<template>
  <!-- 账户信息 -->
  <div class="detail-info"
       v-if="accountData !== []">
    <div class="content"
         v-for="(item, index) in accountData"
         :key="index">
      <el-row>
          <el-col class="div-column details-div"
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="24"
                  :xl="24">
            <div class="div-row details-list">
              <div class="label-title">区块链账户地址</div>
              <popover :width="500" :shard="shardName" :value="item.publicKey" :divClass="'details-content'" :divStyle="[{width:650 + 'px'}]" :isSubStr="false"></popover>
              <i class="el-icon-document-copy"
                 style="margin-left: 10px"
                 @click="copy($event, item.publicKey)"></i>
            </div>
            <div class="div-row-table details-list table-list">
              <div class="label-title">拥有的记账单位</div>

              <div style="width:680px" v-if="item.Tokens">
                <table-list max-height="500"
                            :header-cell-style="{'background':'#eee'}"
                            :columns="tokensColumns"
                            :data="item.Tokens"
                            fit
                            stripe
                            :tableOption="{size: 'mini'}"
                            :pageData="pageData1"
                            @handlePageChange="handlePageChange1"
                            @handleSizeChange="handleSizeChange1">
                  <!-- 记账单位 -->
                  <template #token="{row}">
                    <div class="div-row">
                      <popover :width="500" :shard="shardName" :value="row.token" :divStyle="[{'width':370+'px'}]" :num="40"></popover>
                      <i class="el-icon-document-copy"
                         @click="copy($event, row.token)"></i>
                    </div>
                  </template>
                  <!-- 数量 -->
                  <template #value="{row}">
                    <span>{{toThousands(row.value)}}</span>
                  </template>
                  <!-- 查看按钮 -->
                  <!-- <template #row="{row}">
                    <el-button class="table-el-btn"
                               type="text"
                               icon="ym-icon-liulan"
                               @click="handleOpen(row.token, '记账单位信息')"> 查看</el-button>
                  </template> -->
                </table-list>
              </div>
              <div v-else>无</div>

            </div>
            <div class="div-row-table details-list table-list">
              <div class="label-title">质押中的记账单位</div>

              <div style="width:680px" v-if="item.Deposit">
                <table-list max-height="500"
                            :header-cell-style="{'background':'#eee'}"
                            :columns="depositColumns"
                            :data="item.Deposit"
                            fit
                            stripe
                            :tableOption="{size: 'mini'}"
                            :pageData="pageData2"
                            @handlePageChange="handlePageChange2"
                            @handleSizeChange="handleSizeChange2">
                  <!-- 质押中的记账单位 -->
                  <template #token="{row}">
                    <div class="div-row">
                      <popover :width="500" :shard="shardName" :value="row.token" :divStyle="[{'width':370+'px'}]" :num="40"></popover>
                      <i class="el-icon-document-copy"
                         @click="copy($event, row.token)"></i>
                    </div>
                  </template>
                  <!-- 数量 -->
                  <template #value="{row}">
                    <span>{{toThousands(row.value)}}</span>
                  </template>
                  <!-- 查看按钮 -->
                  <!-- <template #row="{row}">
                    <el-button class="table-el-btn"
                               type="text"
                               icon="ym-icon-liulan"
                               @click="handleOpen(row.token, '记账单位信息')"> 查看</el-button>
                  </template> -->
                </table-list>
              </div>
              <div v-else>无</div>
            </div>
            <!-- 账户拥有的个性凭证 -->
            <div class="div-row-table details-list table-list">
              <div class="label-title">账户拥有的个性凭证</div>

              <div style="width:680px" v-if="item.nonToken">
                <table-list max-height="500"
                            :header-cell-style="{'background':'#eee'}"
                            :columns="nonTokenColumns"
                            :data="item.nonToken"
                            fit
                            stripe
                            :tableOption="{size: 'mini'}"
                            :pageData="pageData3"
                            @handlePageChange="handlePageChange3"
                            @handleSizeChange="handleSizeChange3">
                  <!-- 个性凭证列表 -->
                  <template #hash="{row}">
                    <div class="div-row">
                      <popover :width="500" :shard="shardName" :value="row.hash" :divStyle="[{'width':370+'px'}]" :num="40"></popover>
                      <i class="el-icon-document-copy"
                         @click="copy($event, row.hash)"></i>
                    </div>
                  </template>
                  <!-- 是否销毁 -->
                  <template #recall="{row}">
                    <span v-if="row.recall">已销毁</span>
                    <span v-else>可用</span>
                  </template>
                  <!-- 查看按钮 -->
                  <!-- <template #row="{row}">
                    <el-button class="table-el-btn"
                               type="text"
                               icon="ym-icon-liulan"
                               @click="handleOpen(row.token, '记账单位信息')"> 查看</el-button>
                  </template> -->
                </table-list>
              </div>
              <div v-else>无</div>
            </div>
          </el-col>
        </el-row>

    </div>

    <!-- Dialog -->
    <!-- <el-dialog :title="title"
               width="55%"
               :visible.sync="dialogVisible">
      <pre v-if="info"
           v-html="info"></pre>
      <div v-else>暂无数据</div>
    </el-dialog> -->
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import TableList from '@/components/TableList'
import Popover from '@/components/Popover'
import { getShardName } from '@/utils/localStorage.js'
import publicFunction from '@/utils/publicFunction.js'
export default {
  name: 'accountDetail',
  components: {
    TableList,
    Popover
  },
  data() {
    return {
      shardName: getShardName(),
      accountData: [],
      // 弹窗字段
      dialogVisible: false, // 弹窗
      title: '',
      info: '',
      tokensColumns: [
        {
          label: '记账单位',
          prop: 'token',
          show: true,
          enableSlot: true
        },
        {
          label: '数量',
          prop: 'value',
          show: true,
          enableSlot: true,
          width: 200
        }
      ],
      depositColumns: [
        {
          label: '质押中的记账单',
          prop: 'token',
          show: true,
          enableSlot: true
        },
        {
          label: '数量',
          prop: 'value',
          show: true,
          enableSlot: true,
          width: 200
        }
      ],
      nonTokenColumns: [
        {
          label: '个性凭证',
          prop: 'hash',
          show: true,
          enableSlot: true
        },
        {
          label: '是否销毁',
          prop: 'recall',
          show: true,
          enableSlot: true,
          width: 200
        }
      ],
      pageData1: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      pageData2: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }, // 分页配置
      pageData3: {
        size: 10, // 每页的数据量
        pageSize: 10, // 每页的条数跳转页面
        pageSizes: [10, 20, 50, 100], // 每页的条数跳转页面
        total: 0, // 总条数
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      } // 分页配置
    }
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        // console.log('监听账户:', this.datas)
        // 清空数据
        this.accountData = []
        this.pageData1.currentPage = 1
        this.pageData2.currentPage = 1
        // 查询账户信息
        this.getData()
      },
      deep: false,
      immediate: false // 第一次不会立刻监听handler事件
    }
  },
  created() {
    // this.getData()
  },
  mounted() {
    // console.log('触发账户组件')
    // 查询账户信息
    this.getData()
  },
  methods: {
    // 跳转分页
    handlePageChange1(goPage) {
      this.pageData1.currentPage = goPage
      if (goPage === 1) {
        this.getDataType('Tokens', this.pageData1.pageSize, 0)
      } else if (goPage > 1) {
        this.getDataType('Tokens', this.pageData1.pageSize, this.pageData1.size * (goPage - 1))
      }
    },
    // 配置每页条数
    handleSizeChange1(number) {
      this.pageData1.currentPage = 1
      this.pageData1.size = number
      this.pageData1.pageSize = number
      this.getDataType('Tokens', number, 0)
    },
    // 分页跳转
    handlePageChange2(goPage) {
      this.pageData2.currentPage = goPage
      if (goPage === 1) {
        this.getDataType('Deposit', this.pageData2.pageSize, 0)
      } else if (goPage > 1) {
        this.getDataType('Deposit', this.pageData2.pageSize, this.pageData2.size * (goPage - 1))
      }
    },
    // 配置每页条数
    handleSizeChange2(number) {
      this.pageData2.currentPage = 1
      this.pageData2.size = number
      this.pageData2.pageSize = number
      this.getDataType('Deposit', number, 0)
    },
    // 分页跳转
    handlePageChange3(goPage) {
      this.pageData3.currentPage = goPage
      if (goPage === 1) {
        this.getNonToken(this.pageData2.pageSize, 0)
      } else if (goPage > 1) {
        this.getNonToken(this.pageData2.pageSize, this.pageData2.size * (goPage - 1))
      }
    },
    // 配置每页条数
    handleSizeChange3(number) {
      this.pageData3.currentPage = 1
      this.pageData3.size = number
      this.pageData3.pageSize = number
      this.getNonToken(number, 0)
    },
    // 查询账户信息
    async getData() {
      // 数组
      var obj = {}
      obj.publicKey = this.datas.publicKey
      let options1 = {
        page: 0,
        limit: this.pageData1.size,
        publicKey: this.datas.publicKey,
        type: 'Tokens',
        token: null
      }
      let options2 = {
        page: 0,
        limit: this.pageData2.size,
        publicKey: this.datas.publicKey,
        type: 'Deposit',
        token: null
      }
      let options3 = {
        page: 0,
        limit: this.pageData3.size,
        publicKey: this.datas.publicKey
      }
      let res1 = await this.$api.chainQL.listAccountStatus(options1)
      if (res1.data.listAccountStatus.accountStatus.length !== 0) {
        this.pageData1.total = res1.data.listAccountStatus.total
        obj.Tokens = res1.data.listAccountStatus.accountStatus
      }
      let res2 = await this.$api.chainQL.listAccountStatus(options2)
      if (res2.data.listAccountStatus.accountStatus.length !== 0) {
        this.pageData2.total = res2.data.listAccountStatus.total
        obj.Deposit = res2.data.listAccountStatus.accountStatus
      }
      let res3 = await this.$api.chainQL._tableLedger(options3)
      if (res3.data.listLedger.ledger.length !== 0) {
        this.pageData3.total = res3.data.listLedger.total
        obj.nonToken = res3.data.listLedger.ledger
      }
      this.accountData.push(obj)
    },
    // 分类型单独查询
    async getDataType(type, number, from) {
      let options = {
        page: from,
        limit: number,
        publicKey: this.datas.publicKey,
        type: type,
        token: null
      }
      let { data } = await this.$api.chainQL.listAccountStatus(options)
      if (data.listAccountStatus.accountStatus.length !== 0) {
        this.accountData[0][type] = data.listAccountStatus.accountStatus
      }
    },
    // 查询个性凭证
    async getNonToken(number, from) {
      let options = {
        page: from,
        limit: number,
        publicKey: this.datas.publicKey
      }
      let { data } = await this.$api.chainQL._tableLedger(options)
      if (data.listLedger.ledger.length !== 0) {
        this.accountData[0]['nonToken'] = data.listLedger.ledger
      }
    },
    // 查看详细信息
    // async handleOpen(value, type) {
    //   this.title = type
    //   let option = {
    //     hash: value,
    //     shard: null
    //   }
    //   const { data } = await this.$api.chainQL.ledgerInfo(option)
    //   if (data && data.listLedger) {
    //     if (data.listLedger.ledger.length !== 0) {
    //       this.info = this.syntaxHighlight(data.listLedger.ledger[0])
    //       this.dialogVisible = true
    //     }
    //   } else {
    //     this.$message.error('暂无数据！')
    //   }
    // },
    // 弹窗数据处理
    // syntaxHighlight(_json) {
    //   var json = {}
    //   if (typeof _json !== 'string') {
    //     for (let item in _json) {
    //       var value = _json[item]
    //       if (value == null || value === undefined) {
    //         continue
    //       }
    //       json[item] = value
    //     }
    //     json = JSON.stringify(json, undefined, 2)
    //   }
    //   json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>')
    //   return json.replace(
    //     // eslint-disable-next-line no-useless-escape
    //     /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    //     function (match) {
    //       var cls = 'number'
    //       if (/^"/.test(match)) {
    //         if (/:$/.test(match)) {
    //           cls = 'key'
    //         } else {
    //           cls = 'string'
    //         }
    //       } else if (/true|false/.test(match)) {
    //         cls = 'boolean'
    //       } else if (/null/.test(match)) {
    //         cls = 'null'
    //       }
    //       return '<span class="' + cls + '">' + match + '</span>'
    //     }
    //   )
    // },
    // 数组转成对象数组
    getObject(value) {
      var array = []
      for (let item of value) {
        var obj = {}
        obj['key'] = item
        array.push(obj)
      }
      return array
    },
    // 字符串对象转成对象数组
    getArray(value) {
      let _value = JSON.parse(value)
      var array = []
      for (let key in _value) {
        var obj = {}
        obj.key = key
        obj.value = _value[key]
        array.push(obj)
      }
      return array
    },
    // 数字加逗号转化为金额类型
    toThousands(num) {
      return publicFunction.toThousands(num)
    },
    // 一键复制
    copy(e, text) {
      // console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        // 判断消息提示条数
        if (document.getElementsByClassName('el-message').length > 2) return
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.ym-table .el-table--mini td:last-child {
    padding: 4px 0 4px 24px;
}
.dict-value {
  font-weight: bold;
  color: #3f536e;
  margin-bottom: 10px;
}
.detail-info {
  height: 70vh;
  min-width: 940px;
  .header {
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px 16px;
    border-bottom: 1px solid #cecece;
    background: #fff;
  }
  .content {
    background: #fff;
    margin-bottom: 12px;
    //内容居中布局
    width: 940px;
    position: relative;
    left: 50%;
    transform: translate(-54%);
    .div-column {
      display: flex;
      flex-direction: column;
    }
    .div-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .div-row-table {
      display: flex;
      flex-direction: row;
    }
    .table-list {
      line-height: 24px !important;
      margin-top: 40px;
    }
    .details-div {
      padding-bottom: 20px;
    }
    .details-list {
      line-height: 50px;
      color: #757575;
      .label-title {
        padding-right: 60px;
        width: 240px;
        text-align: right;
        font-weight: bold;
      }
      .details-content {
        position: relative;
        top: 28px;
        line-height: 20px;
        color: #3f536e;
        width: 750px;
        word-wrap: break-word;
        height: 80px;
      }
      .details-table {
        margin-top: 32px;
      }
    }
    .data-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.nothing {
        padding-bottom: 20px;
        margin: 20px auto;
        font-size: 14px;
        color: #222b45;
        img {
          width: 104px;
          height: 105px;
        }
        .nothing-text {
          margin-left: -11px;
        }
      }
    }
  }
  // js样式
  ::v-deep pre {
    min-height: 400px;
    background: rgb(241, 241, 241);
    padding: 10px;
    margin: 10px;
    font-size: 16px;
    overflow-x: scroll;
    .string {
      color: green;
    }
    .number {
      color: darkorange;
    }
    .boolean {
      color: blue;
    }
    .null {
      color: magenta;
    }
    .key {
      color: red;
    }
  }
  ::v-deep pre::-webkit-scrollbar {
    height: 10px;
  }
}
</style>
